import Link from 'next/link';
import {
  FC,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Locales, SocialMedia } from '../../../types/enums';
import {
  ConfigurationResponse,
  NewHeaderMenu,
  SocialMediaResponse,
} from '../../../types/models';
import { ArrowDownIcon, TelephoneIcon, EmailFooterIcon } from '../../icons';
import * as yup from 'yup';

// import Image from 'next/image';

// import Image from 'next/image';
import { useRouter } from 'next/router';
import { isBrowser } from 'react-device-detect';
import ConfigurationService from '../../../helpers/services/configuration.service';
import { useCityContext } from '../../../provider/CityProvider';
import { useTranslationContext } from '../../../provider/TranslationProvider';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { GlobalService } from '../../../helpers/services';
import { toast } from 'react-toastify';
import GogoLoader from '../Loader/Loader';
import SelectCity from '../../home/SelectCity';
import RenderOnViewportEntry from '../../RenderOnViewPortEntry/RenderOnViewportEntry';
import { SessionUtils } from '../../../helpers/utilities';
import IdentifierService from '../../../helpers/services/identifier.service';
import {
  ProdServerHostname,
  SellMyCarPathname,
} from '../../../types/constants';
import { PushSCEventsToGTM } from '../../../helpers/utilities/gtm';
import { SellMyCarEvents } from '../../../types/gtm';
import Image from 'next/image';

var oneYearFromNow = new Date(
  new Date().setFullYear(new Date().getFullYear() + 1)
);
type ChildProps = {
  careerEmail: ConfigurationResponse;
  socialMedia: SocialMediaResponse;
  phoneNumber: string;
  headerMenu: NewHeaderMenu[];
};

type IFormNewsLetters = {
  email: string;
};

const customLoader = ({ src, width, quality }: any) => {
  return `${src}?w=${width}&q=${quality || 75}`;
};

const Footer: FC<ChildProps> = ({
  careerEmail,
  socialMedia,
  phoneNumber,
  headerMenu,
}): ReactElement => {
  const { LabelConstants } = useTranslationContext();
  const [isExplore, setIsExplore] = useState(true);
  const [isCompany, setIsCompany] = useState(true);
  const [isCities, setIsCities] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const { cities, selectCity, setSelectCity } = useCityContext();
  const router = useRouter();
  const [sliders, setSliders] = useState<Array<any>>([]);
  const [isLoadingBannerList, setIsLoadingBannerList] = useState(false);

  const socialMediaUrl = socialMedia;
  const mediaEmail =
    socialMediaUrl && socialMediaUrl.SocialMedia?.length > 0
      ? socialMediaUrl.SocialMedia.filter(
          (itm) => itm.SocialMediaType === SocialMedia.Email
        ).map(function (data) {
          return data.MediaEmail;
        })
      : null;

  const schema = yup.object({
    email: yup
      .string()
      .required(LabelConstants.EMAIL_ADDRESS_REQUIRED)
      .email(LabelConstants.INVALID_EMAIL_ADDRESS),
  });

  const {
    handleSubmit,
    watch,
    formState: { isValid, isDirty, errors },
    reset,
    register,
  } = useForm<IFormNewsLetters>({
    mode: 'onChange',
    resolver: yupResolver(schema),
  });

  const onSubmit: SubmitHandler<IFormNewsLetters> = async (
    data: IFormNewsLetters
  ) => {
    try {
      setIsLoader(true);
      const response = await GlobalService.createStrapiNewsLetter({
        email: data.email,
        isProd: window.location.hostname === ProdServerHostname,
      });
      console.log(response);
      setIsLoader(false);
      if (response?.success) {
        reset();
        toast.success(LabelConstants?.NEWS_LETTERS_SUCCESS, {
          position:
            router.locale === Locales.EN ? 'bottom-right' : 'bottom-left',
        });
      } else {
        toast.info(LabelConstants?.NEWS_LETTER_FAILURE, {
          position:
            router.locale === Locales.EN ? 'bottom-right' : 'bottom-left',
        });
      }
    } catch (error) {
      setIsLoader(false);

      toast.info(LabelConstants?.NEWS_LETTER_FAILURE, {
        position: router.locale === Locales.EN ? 'bottom-right' : 'bottom-left',
      });
    }
  };

  const getSocialMediaURL = (mediaType: SocialMedia) => {
    if (
      socialMediaUrl &&
      socialMediaUrl.SocialMedia &&
      socialMediaUrl.SocialMedia.length > 0
    ) {
      const media = socialMediaUrl.SocialMedia.find(
        (itm) => itm.SocialMediaType.trim() === mediaType
      );
      return media ? media.MediaUrl : '';
    }
    return '';
  };

  const fetchBannersList = useCallback(async () => {
    setIsLoadingBannerList(true);
    try {
      let list: any = await ConfigurationService.getBannersData(
        router.locale,
        'Footerpage'
      );
      setSliders(list);
      setIsLoadingBannerList(false);
    } catch (error) {
      setIsLoadingBannerList(false);
    }
  }, [router.locale]);

  useEffect(() => {
    setIsExplore(true);
    setIsCompany(true);
    setIsCities(true);
  }, [isBrowser]);

  useEffect(() => {
    reset();
  }, [router.locale]);

  useEffect(() => {
    const init = async () => {
      fetchBannersList();
    };
    init();
  }, [fetchBannersList]);

  const DownloadGogomotorApp = () => {
    return (
      <div className="flex flex-col">
        <div className="font-medium text-gray-200 text-[15px]">
          {LabelConstants?.PLEASE_VISIT_APP_STORE}
        </div>
        <div className="flex items-center gap-2 mt-[1rem]">
          <a
            href="https://play.google.com/store/apps/details?id=com.petromin.gogomotor&pli=1"
            rel="noreferrer"
            target="_blank"
            tabIndex={2009}
          >
            <Image
              src={`${process.env.NEXT_PUBLIC_Image_CDN_URL}/CMS/Common/google_play.png`}
              alt="google_play"
              loading="lazy"
              width={88}
              height={30}
              className="rounded"
            />
          </a>
          <a
            href="https://apps.apple.com/sa/app/gogo-motor/id1665705551"
            rel="noreferrer"
            target="_blank"
            tabIndex={2010}
          >
            <Image
              src={`${process.env.NEXT_PUBLIC_Image_CDN_URL}/CMS/Common/app_store.png`}
              alt="app_store"
              loading="lazy"
              width={88}
              height={30}
              className="rounded"
            />
          </a>

          <a
            href="https://appgallery.huawei.com/app/C107741441"
            rel="noreferrer"
            target="_blank"
            tabIndex={2011}
          >
            <Image
              src={`${process.env.NEXT_PUBLIC_Image_CDN_URL}/CMS/Common/app_gallery.png`}
              alt="app_gallery"
              loading="lazy"
              width={88}
              height={30}
              className="rounded"
            />
          </a>
        </div>
      </div>
    );
  };

  const { email: newsletterEmail } = watch();

  return (
    <RenderOnViewportEntry
      id="footer-id"
      threshold={0.3}
      priority="10000"
      style={{ minHeight: '41.66rem' }}
    >
      <footer className="gogo-footer bg-[#F0F0F0] pt-16 min-h-[41.66rem]">
        <div className="gogo-container mobile-container pb-5">
          <div className="grid grid-cols-1 lg:grid-cols-4 gap-0">
            <div>
              <Link href="/">
                <a className="flex items-center" tabIndex={2001}>
                  <picture>
                    <img
                      src={`${process.env.NEXT_PUBLIC_Image_CDN_URL}/CMS/Common/gogo-motors.svg`}
                      alt={LabelConstants?.GOGO_MOTOR_LOGO}
                      loading="lazy"
                      width={98}
                      height={34}
                      className="!h-[34px]"
                    />
                  </picture>
                </a>
              </Link>

              <div className="flex flex-col gap-[1.438rem] items-start mt-5">
                <div className="ml-0 flex flex-col gap-[1.438rem] items-center lg:items-start">
                  <div className="flex gap-3">
                    <a
                      href={getSocialMediaURL(SocialMedia.YouTube)}
                      target="_blank"
                      rel="noopener noreferrer"
                      aria-label={getSocialMediaURL(SocialMedia.YouTube)}
                      tabIndex={2002}
                    >
                      <picture>
                        <img
                          src={`/images/social/youtube.svg`}
                          alt={LabelConstants?.GOGO_MOTOR_YOUTUBE_CHANNEL}
                          loading="lazy"
                          width={20}
                          height={20}
                          className="!h-[20px]"
                        />
                      </picture>
                    </a>
                    <a
                      href={getSocialMediaURL(SocialMedia.LinkedIn)}
                      target="_blank"
                      rel="noopener noreferrer"
                      aria-label={getSocialMediaURL(SocialMedia.LinkedIn)}
                      tabIndex={2003}
                    >
                      <picture>
                        <img
                          src={`/images/social/linkedin.svg`}
                          alt={LabelConstants?.GOGO_MOTOR_LINKEDIN_PAGE}
                          loading="lazy"
                          width={20}
                          height={20}
                          className="!h-[20px]"
                        />
                      </picture>
                    </a>
                    <a
                      href={getSocialMediaURL(SocialMedia.Facebook)}
                      target="_blank"
                      rel="noopener noreferrer"
                      aria-label={getSocialMediaURL(SocialMedia.Facebook)}
                      tabIndex={2004}
                    >
                      <picture>
                        <img
                          src={`/images/social/facebook.svg`}
                          alt={LabelConstants?.GOGO_MOTOR_FACEBOOK_PAGE}
                          loading="lazy"
                          width={20}
                          height={20}
                          className="!h-[20px]"
                        />
                      </picture>
                    </a>
                    <a
                      href={getSocialMediaURL(SocialMedia.Twitter)}
                      target="_blank"
                      rel="noopener noreferrer"
                      aria-label={getSocialMediaURL(SocialMedia.Twitter)}
                      tabIndex={2005}
                    >
                      <picture>
                        <img
                          src={`/images/social/twitter.svg`}
                          alt={LabelConstants?.GOGO_MOTOR_TWITTER_PROFILE}
                          loading="lazy"
                          width={20}
                          height={20}
                          className="!h-[20px]"
                        />
                      </picture>
                    </a>
                    <a
                      href={getSocialMediaURL(SocialMedia.Instagram)}
                      target="_blank"
                      rel="noopener noreferrer"
                      aria-label={getSocialMediaURL(SocialMedia.Instagram)}
                      tabIndex={2006}
                    >
                      <picture>
                        <img
                          src={`/images/social/instagram.svg`}
                          alt={LabelConstants?.GOGO_MOTOR_INSTAGRAM_PROFILE}
                          loading="lazy"
                          width={20}
                          height={20}
                          className="!h-[20px]"
                        />
                      </picture>
                    </a>
                  </div>
                </div>
              </div>

              <div className="my-10 block lg:hidden">
                <DownloadGogomotorApp />
              </div>

              <div className="my-10 hidden lg:block">
                <div className="font-semibold text-gray-200 text-[15px] my-2">
                  {LabelConstants?.NEED_HELP}
                </div>

                <div className="flex flex-col items-center justify-center">
                  <div className="flex gap-3 mt-2  w-full">
                    <div className="mt-1">
                      <TelephoneIcon />
                    </div>
                    <div className="flex flex-col">
                      <span className="cursor-pointer font-normal text-gray-200 text-[15px]">
                        <a tabIndex={2007} href={`tel:${phoneNumber}`}>
                          {phoneNumber}
                        </a>
                      </span>
                      <span className="text-[#757575] text-[13px] font-normal">
                        {LabelConstants?.GOGOMOTOR_WORK_TIME1}
                      </span>
                      {/* <span>{t(LabelConstants.GOGOMOTOR_WORK_TIME2)}</span> */}
                    </div>
                  </div>
                  <div className="flex gap-3 mt-2 w-full">
                    <div className="mt-1">
                      <EmailFooterIcon />
                    </div>
                    <span className="font-normal text-gray-200 text-[15px] cursor-pointer">
                      <a
                        tabIndex={2008}
                        href={`mailto:${mediaEmail && mediaEmail[0]}`}
                      >
                        {mediaEmail && mediaEmail[0]}
                      </a>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-5 lg:mt-0 min-w-max lg:flex gap-[1.438rem] justify-start lg:justify-center items-start">
              <div className="lg:flex flex-col gap-1">
                <div className="flex justify-between">
                  <div className="uppercase mb-3 font-semibold text-gray-200">
                    {LabelConstants?.EXPLORE}
                  </div>
                  <div
                    className="lg:hidden cursor-pointer"
                    onClick={() => setIsExplore(!isExplore)}
                  >
                    <ArrowDownIcon fill="#212121" />
                  </div>
                </div>
                {isExplore && (
                  <>
                    {headerMenu.map((item, index) => {
                      return (
                        !item?.attributes?.IsHide && (
                          <div
                            key={index}
                            tabIndex={2021 + index}
                            onClick={async () => {
                              const clarityId =
                                await IdentifierService.getClarityUserId();
                              if (
                                item?.attributes?.URL &&
                                item?.attributes?.URL.includes(
                                  SellMyCarPathname
                                )
                              ) {
                                PushSCEventsToGTM(SellMyCarEvents.CTA_CLICKED, {
                                  clarity_id: clarityId,
                                  button_location: 'Footer Explore Menu',
                                });
                              }
                              router.push(item?.attributes?.URL);
                            }}
                            className="cursor-pointer flex justify-start mb-2 font-normal text-gray-200 text-[15px]"
                          >
                            {item?.attributes?.Menu}
                          </div>
                        )
                      );
                    })}
                  </>
                )}
              </div>
            </div>
            <div className="mt-5 lg:mt-0 min-w-max lg:flex gap-[1.438rem] justify-start lg:justify-center items-start">
              <div className="lg:flex flex-col gap-1">
                <div className="flex justify-between">
                  <div className="uppercase mb-3 font-semibold text-gray-200">
                    {LabelConstants?.COMPANY}
                  </div>
                  <div
                    className="lg:hidden cursor-pointer"
                    onClick={() => setIsCompany(!isCompany)}
                  >
                    <ArrowDownIcon fill="#212121" />
                  </div>
                </div>
                {isCompany && (
                  <>
                    <div className="flex justify-start mb-2 font-normal text-gray-200 text-[15px]">
                      {LabelConstants?.ABOUT_US && (
                        <Link href="/info/about-us">
                          <a tabIndex={2041}>{LabelConstants?.ABOUT_US}</a>
                        </Link>
                      )}
                    </div>
                    <div className="flex justify-start mb-2 font-normal text-gray-200 text-[15px]">
                      <a
                        tabIndex={2042}
                        href={`mailto:${careerEmail?.ConfigurationValue}`}
                      >
                        {LabelConstants?.CAREERS}
                      </a>
                    </div>
                    <div className="flex justify-start mb-2 font-normal text-gray-200 text-[15px]">
                      {LabelConstants?.CONTACT_US && (
                        <Link href="/info/contact-us">
                          <a tabIndex={2043}>{LabelConstants?.CONTACT_US}</a>
                        </Link>
                      )}
                    </div>
                    <div className="flex justify-start mb-2 font-normal text-gray-200 text-[15px]">
                      {LabelConstants?.FAQ && (
                        <Link href="/info/faq">
                          <a tabIndex={2044}>{LabelConstants?.FAQ}</a>
                        </Link>
                      )}
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className="mt-5 lg:mt-0 min-w-max lg:flex gap-[1.438rem] justify-start lg:justify-center items-start">
              <div className="lg:flex flex-col gap-1">
                <div className="flex justify-between">
                  <div className="uppercase mb-3 font-semibold text-gray-200">
                    {LabelConstants?.AVAILABLE_CITIES}
                  </div>
                  <div
                    className="lg:hidden cursor-pointer"
                    onClick={() => setIsCities(!isCities)}
                  >
                    <ArrowDownIcon fill="#212121" />
                  </div>
                </div>
                {isCities && (
                  <>
                    {cities &&
                      cities.length > 0 &&
                      cities.slice(0, 6).map((item, index) => (
                        <div
                          key={index}
                          tabIndex={2061 + index}
                          className="flex justify-start mb-1 font-normal text-gray-200 text-[15px] cursor-pointer"
                          onClick={() => {
                            setSelectCity(item);
                            localStorage.setItem('city', JSON.stringify(item));
                            router.push(`/all-listings?city=${item?.CityKey}`);
                          }}
                        >
                          {/* <Link href={`/all-listings?city=${item.CityKey}`}> */}
                          {item.City}
                          {/* </Link> */}
                        </div>
                      ))}

                    <div
                      // onKeyUp={() => setOpenModal(true)}
                      onClick={() => setOpenModal(true)}
                      className="cursor-pointer flex justify-start mb-1 font-normal text-gray-200 text-[15px] underline"
                      tabIndex={2081}
                    >
                      {LabelConstants?.SEE_ALL}
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="flex justify-between my-6">
            <div className="hidden lg:block">
              <DownloadGogomotorApp />
            </div>

            <div className="flex flex-col">
              <div className="font-medium text-gray-200 text-[15px]">
                {LabelConstants?.NEWS_LETTERS}
              </div>
              <div className="mt-[1rem]">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="flex rounded-lg shadow-sm">
                    <input
                      className="min-w-[228px] max-w-[252px] h-[44px] border-[#B2B2B2] ltr:border-r-0 rtl:border-l-0 text-[13px] font-normal rounded-[12px] ltr:!rounded-r-none rtl:!rounded-l-none placeholder:text-[13px] placeholder:text-[#0000008A]"
                      placeholder={LabelConstants?.ENTER_YOUR_EMAIL_ADDRESS}
                      {...register('email')}
                    />

                    <button
                      type="submit"
                      disabled={
                        errors.email
                          ? true
                          : newsletterEmail?.length
                          ? false
                          : true
                      }
                      className="min-w-[96px] max-w-[112px] h-[44px] bg-primary disabled:bg-opacity-50 rtl:rounded-l-[12px] ltr:rounded-r-[12px] text-[13px] inline-flex justify-center items-center font-semibold border border-transparent text-white"
                    >
                      {LabelConstants?.SUBSCRIBE}
                    </button>
                  </div>
                  {/* {errors?.email && (
                      <p className="m-2 error">{errors?.email?.message}</p>
                    )} */}
                </form>
              </div>
            </div>
          </div>
          <div className="mt-8 mb-14 lg:hidden">
            <div className="font-semibold text-gray-200 text-[15px] my-2">
              {LabelConstants?.NEED_HELP}
            </div>

            <div className="flex flex-col items-center justify-center">
              <div className="flex gap-3 mt-2  w-full">
                <div className="mt-1">
                  <TelephoneIcon />
                </div>
                <div className="flex flex-col">
                  <span className="cursor-pointer font-normal text-gray-200 text-[15px]">
                    <a href={`tel:${phoneNumber}`}>{phoneNumber}</a>
                  </span>
                  <span className="text-[#757575] text-[13px] font-normal">
                    {LabelConstants?.GOGOMOTOR_WORK_TIME1}
                  </span>
                </div>
              </div>
              <div className="flex gap-3 mt-2 w-full">
                <div className="mt-1">
                  <EmailFooterIcon />
                </div>
                <span className="font-normal text-gray-200 text-[15px] cursor-pointer">
                  <a href={`mailto:${mediaEmail && mediaEmail[0]}`}>
                    {mediaEmail && mediaEmail[0]}
                  </a>
                </span>
              </div>
            </div>
          </div>
          {/* <hr className="!border-[#C1C1C1] mt-5" /> */}

          {/* <div className="grid grid-cols-1 gap-5">
          <div className="md:hidden flex items-center justify-center mt-2">
            <Image
              src={`/images/footer-logo.png`}
              alt="footer-logo"
              loading="lazy"
              width={49}
              height={34}
            />
          </div>
        </div> */}
          {/* <div className="flex items-center justify-between lg:grid grid-cols-3 lg:grid-cols-8 lg:grid-cols-8 gap-5 lg:gap-10 mx-[11px] py-[13px]"> */}
          {/* <div className="hidden lg:flex items-center justify-start lg:justify-center">
            <Image
              src={`/images/footer-logo.png`}
              alt="footer-logo"
              loading="lazy"
              width={49}
              height={34}
            />
            <div className="h-[64.43px] min-h-[1em] w-[1.74px] bg-gray-200 hidden lg:block mx-3"></div>
          </div> */}
          {/* <div className="">
              <Image
                src={`/images/gogo-brand/1.svg`}
                alt="gogo-brand-logo"
                loading="lazy"
                width={80}
                height={56}
              />
            </div>
            <div className="">
              <Image
                src={`/images/gogo-brand/2.svg`}
                alt="gogo-brand-logo"
                loading="lazy"
                width={80}
                height={56}
              />
            </div>
            <div className="">
              <Image
                src={`/images/gogo-brand/3.svg`}
                alt="gogo-brand-logo"
                loading="lazy"
                width={38.17}
                height={42.67}
              /> */}
          {/* </div> */}
          {/* </div> */}
          <hr className="!border-[#C1C1C1]  mb-5" />
          <div className="grid grid-cols-12 gap-0">
            <div className="col-span-9 flex items-center mt-8 lg:mt-0">
              <ul className="lg:flex font-light list-none lg:list-disc list-inside text-[11px] text-gray-200">
                <li className="list-none lg:mr-5  mb-2 lg:mb-0">
                  {LabelConstants?.PRIVACY_POLICY && (
                    <Link href="/info/privacy-policy">
                      <a tabIndex={2101}> {LabelConstants?.PRIVACY_POLICY}</a>
                    </Link>
                  )}
                </li>
                <li className="lg:mr-5 mb-2 lg:mb-0">
                  {LabelConstants?.TERMS_CONDITIONS && (
                    <Link href="/info/terms-and-conditions">
                      <a tabIndex={2102}> {LabelConstants?.TERMS_CONDITIONS}</a>
                    </Link>
                  )}
                </li>

                <li className="lg:mr-5 mb-2 lg:mb-0">
                  {LabelConstants?.SALES_AND_PURCHASE && (
                    <Link href="/info/terms-and-conditions#sales-and-purchase-policy">
                      <a tabIndex={2103}>
                        {LabelConstants?.SALES_AND_PURCHASE}
                      </a>
                    </Link>
                  )}
                </li>

                <li className="lg:mr-5 mb-2 lg:mb-0">
                  {LabelConstants?.REFUND_AND_CANCELLATION && (
                    <Link href="/info/terms-and-conditions#refund-and-cancellation-policy">
                      <a tabIndex={2104}>
                        {LabelConstants?.REFUND_AND_CANCELLATION}
                      </a>
                    </Link>
                  )}
                </li>
              </ul>
            </div>
            <div className="col-span-12 lg:col-span-3 order-first lg:order-last">
              <div className="mb-3 font-light text-[11px] text-gray-200">
                {LabelConstants?.ACCEPTED_PAYMENT_METHODS}
              </div>

              <div className="flex flex-wrap ustify-start gap-3">
                <picture>
                  <img
                    src={`/images/payments/1.png`}
                    alt="stcpay"
                    loading="lazy"
                    width={60}
                    height={17}
                    className="!h-[17px]"
                  />
                </picture>
                <picture>
                  <img
                    src={`/images/payments/2.png`}
                    alt="visacard"
                    loading="lazy"
                    width={28}
                    height={17}
                    className="!h-[17px]"
                  />
                </picture>

                <picture>
                  <img
                    src={`/images/payments/3.png`}
                    alt="american-express"
                    loading="lazy"
                    width={29}
                    height={17}
                    className="!h-[17px]"
                  />
                </picture>

                <picture>
                  <img
                    src={`/images/payments/4.png`}
                    alt="mastercard"
                    loading="lazy"
                    width={28}
                    height={17}
                    className="!h-[17px]"
                  />
                </picture>

                <picture>
                  <img
                    src={`/images/payments/5.png`}
                    alt="casamada"
                    loading="lazy"
                    width={34}
                    height={17}
                    className="!h-[17px]"
                  />
                </picture>

                <picture>
                  <img
                    src={`/images/payments/6.png`}
                    alt="applepay"
                    loading="lazy"
                    width={31}
                    height={17}
                    className="!h-[17px]"
                  />
                </picture>
              </div>
            </div>
          </div>
          <p className="font-light text-[12px] py-5 text-gray-200">
            {LabelConstants?.COPYRIGHT} {new Date()?.getFullYear()}-
            {oneYearFromNow?.getFullYear()} {LabelConstants?.COPYRIGHT_OTHER}
            {LabelConstants?.GOGOMOTOR_ADDRESS}
          </p>
        </div>

        {sliders?.length > 0 && (
          <div className="relative w-full aspect-[360/247] lg:aspect-[1440/454] min-h-[225px]">
            <picture>
              <img
                alt="banner"
                // loader={customLoader}
                src={`${process.env.NEXT_PUBLIC_CMS_API_URL}${sliders[0]?.attributes?.MobileImage?.data?.attributes?.url}`}
                // layout="fill"
                // objectFit="cover"
                loading="lazy"
                width={360}
                height={247}
                className="object-cover !h-[247px]"
              />
            </picture>
            <picture>
              <img
                alt="special images"
                // objectFit="cover"
                width={1440}
                height={454}
                src={`${process.env.NEXT_PUBLIC_CMS_API_URL}${sliders[0]?.attributes?.Image?.data?.attributes?.url}`}
                // layout="fill"
                className="invisible lg:visible object-cover !h-[454px]"
              />
            </picture>
          </div>
        )}
      </footer>

      {openModal && (
        <SelectCity
          cities={cities}
          show={openModal}
          onClose={(val: any) => {
            if (val) {
              setSelectCity(val);
              localStorage.setItem('city', JSON.stringify(val));
              router.push(`/all-listings?city=${val?.CityKey}`);
            }
            setOpenModal(false);
          }}
        />
      )}
    </RenderOnViewportEntry>
  );
};

export default Footer;
